import React from "react";

const CartToggle = ({ setToggle, toggle }) => {
  return (
    <div className="px-1 md:px-10 py-2 md:py-5 md:mb-0">
      <span className="flex w-full justify-center mb-5 text-gray-500">
        <h1
          onClick={(e) => setToggle((prev) => (prev = true))}
          className={`text-sm  md:text-xl w-1/2 text-center p-2 ${
            toggle === true
              ? "border-b-2 md:border-b-4 border-[#013220] text-black"
              : "border-b-2"
          }`}
        >
          My Cart
        </h1>
        <h1
          onClick={(e) => setToggle((prev) => (prev = false))}
          className={`text-sm md:text-xl w-1/2 text-center p-2 ${
            toggle === false
              ? "border-b-2 md:border-b-4 border-[#013220] text-black"
              : "border-b-2"
          }`}
        >
          My Orders
        </h1>
      </span>
    </div>
  );
};

export default CartToggle;
