import BackIcon from "../../Components/BackIcon";
import { primaryColor } from "../../constants/constants";

export default function About() {
  return (
    <div style={{ padding: "20px" }}>
      <BackIcon page={"About"} />
      <p style={{ marginTop: "10px" }}>
        About Geften Electrical Solutions LLP Welcome to Geften Electrical
        Solutions LLP, your trusted source for high-quality house wires and
        cables. Our commitment to delivering top-notch products and ensuring
        customer satisfaction is unwavering. Our dedication to excellence
        remains steadfast.
      </p>
      <h4>
        <span style={{ color: primaryColor }}>Developed by</span> : Codescap
      </h4>
      <h4>
        <span style={{ color: primaryColor }}>Manufactured by</span> : Geften
        Electrical Solutions
      </h4>
    </div>
  );
}
