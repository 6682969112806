import { Modal, Select } from "antd";
import { Edit } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";

import styles from "./home.module.css";
import { UserStates } from "../../context/UserContext";
import { getUserDetailsUrl } from "../../apis/constants";
import axios from "axios";

export default function EditUserModal({ show, setShow }) {
  const { accessToken } = useContext(UserStates);

  const userId = localStorage.getItem("userId");

  const [user, setUser] = useState(null);

  const changeValue = (e) =>
    setUser({ ...user, [e.target.name]: e.target.value });

  // get user details
  const getUserDetails = () => {
    const config = {
      method: "get",
      url: `${getUserDetailsUrl}${userId}`,
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => {
        setUser(res.data.data);
        localStorage.setItem("profile-pic", res.data.data.image);
      })
      .catch((err) => err);
  };

  // update user details
  const updateUserDetails = () => {
    const config = {
      method: "patch",
      url: `${getUserDetailsUrl}${userId}`,
      data: user,
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => setUser(res.data.data))
      .catch((err) => err);
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  console.log("user values", user);

  return (
    <Modal
      open={show}
      onClose={() => setShow(false)}
      onCancel={() => setShow(false)}
      className={styles.modal}
      okText="Save"
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      style={{ width: "700px" }}
    >
      <p style={{ fontSize: "20px" }}>Edit Profile</p>
      <div>
        <div className={styles.modalImageContainer}>
          <img src={localStorage.getItem("profile-pic")} />
          <div className={styles.imgEditIcon}>
            <Edit />
          </div>
        </div>
        <div className={styles.modalInputs}>
          <div className={styles.inputContainer}>
            <label>Name</label>
            <input
              name="name"
              placeholder="Enter your name"
              value={user?.name}
              onChange={(e) => changeValue(e)}
            />
          </div>
          <div className={styles.inputContainer}>
            <label>Business Name</label>
            <input
              name="business_name"
              placeholder="Enter your business' name"
              value={user?.business_name}
              onChange={(e) => changeValue(e)}
            />
          </div>
          <div className={styles.inputContainer}>
            <label>Alternative Number</label>
            <input
              name="alternative_number"
              type="tel"
              placeholder="Enter your name"
              value={user?.alternative_number}
              onChange={(e) => changeValue(e)}
            />
          </div>
          <div className={styles.inputContainer}>
            <label>Are you a?</label>
            <Select
              value={user?.type}
              onChange={(e) => setUser({ ...user, type: e.target.value })}
            >
              <Select.Option value={5}>Retailer</Select.Option>
              <Select.Option value={6}>Distributor</Select.Option>
              <Select.Option value={4}>Channel Partner Gold</Select.Option>
              <Select.Option value={7}>Channel Partner Diamond</Select.Option>
            </Select>
          </div>

          <button onClick={updateUserDetails}>Save</button>
        </div>
      </div>
    </Modal>
  );
}
