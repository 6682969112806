import React, { useState } from "react";
import AddressForm from "./AddressForm";
import Map from "./Map";
import { FaChevronUp } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";

const AddAddress = () => {
  const [add, setAdd] = useState(false);
  const [select, setSelect] = useState("form");
  return (
    <div>
      <div
        onClick={(e) => setAdd((pre) => !pre)}
        className="text-lg text-[#00BF63] bg-white p-2 border border-gray-300 rounded-lg mb-2 flex justify-between items-center pe-6"
      >
        <p>Add new Address</p>
        {add?<FaChevronUp className="text-gray-500 text-sm"/>:<FaChevronDown className="text-gray-500 text-sm"/>}
      </div>
      {add && (
        <div>
          <Map setSelect={setSelect} />
          {select === "map" && (
            <div className="bg-white p-2 mb-2 mt-2 rounded-md">
              <div className="flex gap-2">
                <input className=" accent-[#00BF63] " type="radio" checked={select==="map"} name="form" id="" />
                <p className={`${select==="map"?"text-[#00BF63]":"text-black"}`}>Your location</p>
              </div>
              <div>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Numquam, laborum mollitia! Veritatis facere, debitis recusandae.
              </div>
            </div>
          )}
          <div className=" bg-white p-2 rounded-md">
            <div className="flex gap-2 mb-1">
              <input className="accent-[#00BF63]" onChange={(e)=>setSelect("form")} checked={select==="form"} type="radio" name="form" id="1" />
              <p className={`${select==="form"?"text-[#00BF63]":"text-black"}`}>Enter location</p>
            </div>
            <AddressForm />
          </div>

          <div className="flex justify-center gap-3 px-3 mt-3">
            <div className="text-[#013220] bg-white py-2 text-center rounded-md w-72 mb-5">
              Cancel
            </div>
            <div className="bg-[#013220] text-white py-2 text-center rounded-md w-72 mb-5">
              Add Address
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddAddress;
