import React, { useState } from 'react'
import Toggle from '../../Components/AllMarketPlace/Toggle'
import { NavbarDefault } from '../../Components/Home/Navbar'
import MyListings from '../../Components/AllMarketPlace/MyListings'
import MarketPlace from '../../Components/AllMarketPlace/MarketPlace'
import Footer from '../../Components/Home/Footer'

const AllMarketPlace = () => {
  const [toggle,setToggle]=useState(true)
  return (
    <div className='w-screen bg-[#F5F5F5]'>
      <NavbarDefault/>
      <Toggle setToggle={setToggle} toggle={toggle} />
      <div className='md:mb-6'>
        {toggle ? <MyListings /> : <MarketPlace />}
      </div>
      <Footer/>
    </div>
  )
}

export default AllMarketPlace