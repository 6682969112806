import React from "react";
import { IoStar } from "react-icons/io5";

function Item({ product }) {
  return (
    <div className="flex h[161px] box_shadow bg-[#FAFAFA] rounded-md my-5 w-sc">
      <div
        className={`flex justify-center items-center ${
          !product.images[0] && "bg-[#CCCCCC]"
        }`}
      >
        <img
          src={product.images[0]}
          className="h-[151px] m-1 w-[133px]"
          alt="product image"
        />
      </div>
      <div className=" w-3/4 md:w-[400px] xl:w-[551px] mt-3 md:mt-6 ml-5 pr-8 xl:mr-8 ">
        <p className="text-[#252525] mb-2 poppins-medium text-[14px] xl:text-[16px] ">
          {product.name.charAt(0).toUpperCase() +
            product.name.slice(1).toLowerCase()}
        </p>
        <p className="text-[#777777] xm:w-10/12 text-[10px] md:text-[13px] xm:text-[14px] hidden md:block  lg:text-[13px] xl:text-[14px] md:leading-[21px]">
          {product.description}
        </p>
        <p className="text-[#777777] md:hidden text-[10px]">
          {/* Blade Material Is Aluminium. Brand Color Is Brown. Pack Of Is 1. Model
          Name Is Energion... */}
          {product.description}
        </p>
        <div className="md:hidden mt-3">
          <div className="flex md:justify-between">
            <IoStar className="text-[#F2DA5D] text-[15px] mx-1" />
            <IoStar className="text-[#F2DA5D]   text-[15px] mx-1" />
            <IoStar className="text-[#F2DA5D]  text-[15px] mx-1" />
            <IoStar className="text-[#F2DA5D]  text-[15px] mx-1" />
            <IoStar className="text-[#F2DA5D]  text-[15px] mx-1" />
          </div>
          <div className="flex items-center">
            {product?.hidden_price ? (
              <a
                href={`https://api.whatsapp.com/send?phone=+91${product?.user?.number}&text=Hello,%20May%20I%know%20the%20price%20of%20the%20product%20${product?.name}%20from%20Gefmart%20Marketplace`}
                target="_blank"
                rel="noreferrer"
                style={{ minWidth: "fit-content", color: "#00BF63" }}
              >
                <p>* Contact for price</p>
              </a>
            ) : (
              <p className=" text-xl font-medium text-[#00BF63] mt-2">
                ₹{product?.retail_price}/piece
              </p>
            )}
            {/* <p className="poppins-medium text-[#013220] text-[22px] lg:text-[20px] xl:text-[24px] font-[500] mr-3">
              ₹ 2,459/piece
            </p> */}
            {/* <p className='line-through text-[#CCCCCC] text-[16px] lg:text-[14px] xl:text-[16px]'>₹2,459</p> */}
          </div>
        </div>
      </div>
      <div className="hidden md:block mt-6 pr-5">
        <div className="flex md:justify-between">
          <IoStar className="text-[#F2DA5D]  text-xl xm:text-2xl lg:text-xl xl:text-3xl mx-1" />
          <IoStar className="text-[#F2DA5D]   text-xl xm:text-2xl lg:text-xl xl:text-3xl mx-1" />
          <IoStar className="text-[#F2DA5D]   text-xl xm:text-2xl  lg:text-xl xl:text-3xl mx-1" />
          <IoStar className="text-[#F2DA5D]    text-xl xm:text-2xl  lg:text-xl xl:text-3xl mx-1" />
          <IoStar className="text-[#F2DA5D]   text-xl xm:text-2xl  lg:text-xl xl:text-3xl mx-1" />
        </div>
        {/* <p className="poppins-medium text-[#013220] text-[22px] lg:text-[20px] xl:text-[24px] font-[500] mt-2">
          ₹ {product.retail_price}/piece
        </p> */}
        <div style={{ marginTop: "10px" }}>
          {product?.hidden_price ? (
            <a
              href={`https://api.whatsapp.com/send?phone=+91${product?.user?.number}&text=Hello,%20May%20I%know%20the%20price%20of%20the%20product%20${product?.name}%20from%20Gefmart%20Marketplace`}
              target="_blank"
              rel="noreferrer"
              style={{ minWidth: "fit-content", color: "#00BF63" }}
              className="poppins-medium text-[#013220] text-[22px] lg:text-[20px] xl:text-[24px] font-[500] mt-2"
            >
              <p>* Contact for price</p>
            </a>
          ) : (
            <p className="poppins-medium text-[#013220] text-[22px] lg:text-[20px] xl:text-[24px] font-[500] mt-2">
              ₹{product?.retail_price}/piece
            </p>
          )}
        </div>
        {/* <p className='line-through text-[#CCCCCC] text-[16px] lg:text-[14px] xl:text-[16px]'>₹2,459</p> */}
      </div>
    </div>
  );
}

export default Item;
