import React, { useContext, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { userRegister } from "../../apis/user";
import Cookies from "js-cookie";
import { UserStates } from "../../context/UserContext";

function SignUp() {
  const { setAccessToken } = useContext(UserStates);
  
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const parmas = useLocation();

  useEffect(() => {
    const disableBackButton = (event) => {
      event.preventDefault();
      window.history.forward();
    };

    if (parmas.state) {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener("popstate", disableBackButton);
    }

    return () => {
      window.removeEventListener("popstate", disableBackButton);
    };
  }, [parmas.state]);

  //on submit
  const onSubmit = async (data) => {
    const { checkbox, ...rest } = data;
    if (parmas.state) {
      const user = { ...rest, number: parmas.state, type: parseInt(rest.type) };
      const res = await userRegister(user);
      if (res.data.success) {
        setAccessToken(`Bearer ${res.data.data.token}`);
        Cookies.set("gefmartUserToken", `Bearer ${res.data.data.token}`);
        navigate("/");
      }
    }
  };

  return (
    <>
      <div className="bg-[#013220] w-screen h-screen md:h-[1024px]  text-white relative overflow-y-hidden overflow-x-hidden">
        <div className="login absolute h-5/6 w-full z-0 overflow-hidden"></div>

        {/* <img className='absolute top-0 -rotate-26' src={'https://s3-alpha-sig.figma.com/img/6db8/a991/e7dc7f9341b35db4b7ea81385f64a294?Expires=1710115200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=X~SsevZsqTkXOIasldngDZ54DIK8jMU-ShNUeQfA2UURs5Z5wV6dHZJ6kciyzQ~4iM4xS0eQf9pEQ8ae~xh~0JRsY~Sy0p15rxOrlpFUSQ1AC7EkHfSubxpqEjMMyBHSYMewA1CsZWVgO00S7miXuuaBDwkcr-IZ2Cud2BP94Xzetjr0NZUuXXx5x2ZDlcnGauCMEfLJ6~wUH2vMAJjW9unhn81C5BxlFsVRhqZKCFCIho-s~0bOuz0OXzzRMSictgxy6yr8jV6zBhd9pik4M79-hojk5VPekrwFr5KBgtupmHZKyON3fw9o29yuc-bY9T3ZUpJBnwV1BWN3TPpblQ__'}/> */}
        <div className="backdrop-blur-ss md:ml-[140px]">
          <div className="hidden md:flex pt-[70px]">
            <div className="h-[50px] w-[50px] bg-[#00BF63] rounded-md"></div>
            <div className="h-[50px] w-[50px] border-2 border-white rounded-md mx-5"></div>
          </div>
          <div className="md:flex md:w-[1114px] md:h-full mt-24">
            <div className="hidden md:block mr-32">
              <h1 className="text-xl font-medium text-[34px]">Register</h1>
              <p className="text-[#B0C2BB] leading-[30px] mt-12 text-[18px]">
                Ready to save big?Log in and explore our exclusive offers and
                discounts on a wide range of items
              </p>
              <p className="text-[#B0C2BB] leading-[30px] mt-12 text-[18px]">
                <span className="text-[24px] font-medium text-white">
                  Connect for convenience!
                </span>{" "}
                Explore our e-commerce hub for electrical appliances with just a
                click. Your gateway to effortless shopping and unbeatable deals
                is a tap away. Get plugged in now!
              </p>
            </div>
            <form
              className="  bg-white p-3  md:p-[40px] h-[653px] md:w-[512px] rounded-[20px] text-black"
              onSubmit={handleSubmit(onSubmit)}
            >
              <h1 className="text-2xl font-semibold md:hidden w-full text-center my-3">
                Register
              </h1>
              <div className="w-full">
                <p className="mb-2">Name</p>
                <input
                  className="h-[50px] w-full md:w-[420px] bg-[#F5F5F5]  rounded-[10px] focus:outline-none px-4 mr-2"
                  placeholder="Name"
                  {...register("name", { required: true })}
                />
                {errors.name && (
                  <span className="text-red-400 text-xs">
                    This field is required
                  </span>
                )}
              </div>
              <div className="my-5 w-full">
                <p className="mb-2">Buisness Name</p>
                <input
                  className="h-[50px] w-full md:w-[420px] bg-[#F5F5F5]  rounded-[10px] focus:outline-none px-4 mr-2"
                  placeholder="Buisness Name"
                  {...register("business_name", { required: true })}
                />
                {errors.buisness_name && (
                  <span className="text-red-400 text-xs">
                    This field is required
                  </span>
                )}
              </div>
              <div className="w-full">
                <p className="mb-2">Alternate Phone number (optional) </p>
                <div className="flex w-full">
                  <p className="flex justify-center items-center h-[50px] w-[50px] bg-[#F5F5F5] rounded-[10px] focus:outline-none text-center text-slate-500">
                    +91
                  </p>
                  <div className="ml-3 flex bg-[#F5F5F5] rounded-[10px] w-full">
                    <input
                      className="h-[50px] w-full md:w-[360px] bg-[#F5F5F5] rounded-[10px] focus:outline-none px-4 mr-2"
                      placeholder="Enter phone number here"
                      {...register("alternative_number", {
                        pattern: {
                          value: /^\d{10}$/,
                          message: "Please enter a valid 10-digit phone number",
                        },
                      })}
                    />
                  </div>
                </div>
                {errors.phone_number && (
                  <span className="text-red-400 text-xs">
                    {errors.phone_number.message}
                  </span>
                )}
              </div>
              <div className="my-5 w-full">
                <p className="mb-2">Are you a?</p>
                <select
                  className="h-[50px] select cursor-pointer w-full md:w-[420px] p-2 bg-[#F5F5F5] rounded-[10px] focus:outline-none px-4 mr-2"
                  {...register("type", { valueAsNumber: true })}
                >
                  <option value={3}>Delivery Boy</option>
                  <option value={4}>Dealer</option>
                  <option value={5}>Wholesaler</option>
                  <option value={6}>Customer</option>
                </select>
              </div>
              <div className="flex items-center mb-5 ">
                <input
                  type="checkbox"
                  id="myCheckbox"
                  className="form-checkbox h-4 w-4 text-blue-500"
                  {...register("checkbox", { required: true })}
                />
                <p className="mx-4">I agree to the terms of condition</p>
              </div>
              <div className="flex flex-col gap-3 md:gap-0 md:flex-row justify-between items-center">
                <p>
                  Already have an account?{" "}
                  <span className="underline text-[#00BF63]">Sign in </span>
                </p>
                <button
                  className="h-[54px] w-[133px] bg-[#013220] text-white rounded-[10px]"
                  type="submit"
                >
                  Register
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
