import Api from "../utils/Api";
import blogs from "../utils/endpoints/Blog";

export const getAllBlogs = async () => {
  try {
    const res = await Api.post(blogs.getBlogs());
    return res;
  } catch (err) {
    throw err;
  }
};
