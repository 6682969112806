import { useRoutes } from "react-router-dom";

import "./App.css";
import Login from "./Pages/Login/Login";
import SignUp from "./Pages/Signup/SignUp";
import Home from "./Pages/Home/Home";
import Product from "./Pages/Products/Product";
import ProductDetail from "./Pages/ProductDetail/ProductDetail";
import PrivateRoute from "./Components/routeProtection/privateRoute";
import Cart from "./Pages/Cart/Cart";
import AllMarketPlace from "./Pages/AllMarketPlace/AllMarketPlace";
import MarketPlace from "./Pages/MarketPlace/MarketPlace";
import Categories from "./Pages/Categories/Categories";
import Blogs from "./Pages/Blogs/Blogs";
import FullBlog from "./Pages/FullBlog/FullBlog";
import MarketPlaceProductDetails from "./Components/MarketPlace/Details";
import Warehouse from "./Pages/Warehouse";
import MarketPlacePage from "./Pages/MarketPlace/marketplaceWithSearch";
import Brands from "./Pages/Brands";
import About from "./Pages/About";
import Help from "./Pages/Help";
import OrderHistory from "./Pages/OrderHistory";
import Profile from "./Pages/Profile";
import AddToMarketPlacePage from "./Pages/MarketPlace/add";

function App() {
  const element = useRoutes([
    {
      path: "/",
      element: (
        <PrivateRoute>
          <Home />
        </PrivateRoute>
      ),
    },
    {
      path: "/login",
      element: (
        <PrivateRoute>
          <Login />
        </PrivateRoute>
      ),
    },
    {
      path: "/signup",
      element: (
        <PrivateRoute>
          <SignUp />
        </PrivateRoute>
      ),
    },
    {
      path: "/products",
      children: [
        {
          index: true,
          element: (
            <PrivateRoute>
              <Product />
            </PrivateRoute>
          ),
        },
        {
          path: ":id",
          element: <ProductDetail />,
        },
      ],
    },
    {
      path: "/cart",
      element: (
        <PrivateRoute>
          <Cart />
        </PrivateRoute>
      ),
    },
    {
      path: "/allmarketplace",
      element: (
        <PrivateRoute>
          <AllMarketPlace />
        </PrivateRoute>
      ),
    },
    {
      path: "/marketplace",
      children: [
        {
          index: true,
          element: (
            <PrivateRoute>
              <MarketPlacePage />
            </PrivateRoute>
          ),
        },
        {
          path: ":id",
          element: (
            <PrivateRoute>
              <MarketPlaceProductDetails />
            </PrivateRoute>
          ),
        },
        {
          path: "add",
          element: (
            <PrivateRoute>
              <AddToMarketPlacePage />
            </PrivateRoute>
          ),
        },
        {
          path: "category/:id",
          element: (
            <PrivateRoute>
              <MarketPlacePage />
            </PrivateRoute>
          ),
        },
      ],
    },
    {
      path: "/categories",
      element: (
        <PrivateRoute>
          <Categories />
        </PrivateRoute>
      ),
    },
    {
      path: "/categories/brand",
      element: (
        <PrivateRoute>
          <Categories />
        </PrivateRoute>
      ),
    },
    {
      path: "/blog",
      children: [
        {
          index: true,
          element: (
            <PrivateRoute>
              <Blogs />
            </PrivateRoute>
          ),
        },
        {
          path: ":id",
          element: (
            <PrivateRoute>
              <FullBlog />
            </PrivateRoute>
          ),
        },
      ],
    },
    {
      path: "/warehouse",
      children: [
        {
          index: true,
          element: (
            <PrivateRoute>
              <Warehouse />
            </PrivateRoute>
          ),
        },
        {
          path: ":id",
          element: (
            <PrivateRoute>
              <ProductDetail />
            </PrivateRoute>
          ),
        },
      ],
    },
    {
      path: "/brands",
      element: (
        <PrivateRoute>
          <Brands />
        </PrivateRoute>
      ),
    },
    {
      path: "/about",
      element: (
        <PrivateRoute>
          <About />
        </PrivateRoute>
      ),
    },
    {
      path: "/help",
      element: (
        <PrivateRoute>
          <Help />
        </PrivateRoute>
      ),
    },
    {
      path: "/orders",
      element: (
        <PrivateRoute>
          <OrderHistory />
        </PrivateRoute>
      ),
    },
    {
      path: "/profile",
      element: (
        <PrivateRoute>
          <Profile />
        </PrivateRoute>
      ),
    },
  ]);
  return element;
}

export default App;
