import axios from "axios";
import Cookies from "js-cookie";

const baseURL = "https://server.gefmart.com";

const token = Cookies.get("gefmartUserToken") || "";
const Api = axios.create({
  baseURL: baseURL,
  headers: { Authorization: token },
});

export default Api;
