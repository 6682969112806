import React from "react";
import { RxCross2 } from "react-icons/rx";
import { IoSearch } from "react-icons/io5";

import AddAddress from "./AddAddress";
import EditAddress from "./EditAddress";

const ManageAddress = () => {
  return (
    <div>
      <div
        id="confirmation-modal"
              data-modal-backdrop="static"
        tabIndex={-1}
        aria-hidden="true"
        className={`${"hidden"}  inset-0 z-50 flex items-center justify-center`}
      >
        <div className="absolute inset-0 bg-gray-800 opacity-75"></div>
        <div className="relative w-screen max-w-md h-full md:h-auto">
          <div className=" rounded-lg shadow relative border border-mark  bg-[#F5F5F5] p-5 overflow-y-auto max-h-screen">
            <div className="flex justify-between p-2">
              <h1 className=" text-lg">Manage Addresses</h1>
              <div className="flex items-center">
                <RxCross2 className=" text-gray-700 text-lg" />
              </div>
            </div>
            <div className=" border-b-2 py-3 ">
              <div className="mb-2">
                <div class="relative">
                  <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"></div>
                  <input
                    type="search"
                    id="default-search"
                    class="block w-full p-3 md:ps-8 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 :bg-gray-700 :border-gray-600 :placeholder-gray-400 :text-white :focus:ring-blue-500 :focus:border-blue-500"
                    placeholder="Search location"
                    required
                  />
                  <button
                    type="submit"
                    class="text-white absolute end-2.5 bottom-2.5 font-medium rounded-lg text-sm px-4 py-1"
                  >
                    <IoSearch className="text-lg text-black" />
                  </button>
                </div>
              </div>
              <AddAddress />
            </div>
            <div className="mt-3">
              <EditAddress />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageAddress;
