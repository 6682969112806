import Api from "../utils/Api";
import { userEndpoints } from "../utils/endpoints/user";
import { notifyError } from "../utils/notification";

export const sendOTP = async (phone) => {
  try {
    const res = await Api.post(userEndpoints.sendOTP, { phone });
    return res;
  } catch (err) {
    notifyError(err.response.data.message);
    return;
  }
};

export const verifyOTP = async (phone, otp) => {
  try {
    const res = await Api.post(userEndpoints.verifyOTP, { phone, otp });
    return res;
  } catch (err) {
    notifyError(err.response.data.message);
    return;
  }
};

export const userRegister = async (user) => {
  try {
    const res = await Api.post(userEndpoints.register, user);
    return res;
  } catch (err) {
    console.log(err);
    notifyError(err.response.data.message);
    return;
  }
};
