import React from "react";
import { RiDeleteBin6Line } from "react-icons/ri";

const EditMarketPlace = ({ isEdit, setEdit }) => {
  return (
    <div>
      <div
        id="confirmation-modal"
        aria-hidden="true"
        className={`${
          isEdit ? "fixed" : "hidden"
        }  inset-0 z-50 flex items-center justify-center `}
      >
        <div className="absolute inset-0 bg-gray-800 opacity-75"></div>
        <div className="relative w-screen max-w-md h-full md:h-auto">
          <div className=" rounded-lg shadow relative border border-mark  bg-[#F5F5F5]">
            <div className="flex justify-between p-2">
              <h1 className=" text-lg"> Edit Marketplace</h1>
              <div className="flex items-center">
                <RiDeleteBin6Line className=" text-red-700 text-lg"/> <p className="text-red-700">Delete</p>
              </div>
            </div>
            <div className="w-full flex flex-col  h-full  mb-2 gap-2 p-3">
              <div className=" grid grid-cols-2 gap-6">
                <div>
                  <h1>Product name</h1>
                  <input type="text" className=" py-1 rounded-md" />
                </div>
                <div>
                  <h1>Category</h1>
                  <input type="text" className=" py-1 rounded-md" />
                </div>
                <div>
                  <h1>Brand</h1>
                  <input type="text" className=" py-1 rounded-md" />
                </div>
                <div>
                  <h1>Price</h1>
                  <input type="text" className=" py-1 rounded-md" />
                </div>
              </div>
              <div>
                <h1>Tags</h1>
                <input type="text" className=" py-1 rounded-md w-full" />
              </div>
              <div>
                <h1>Description</h1>
                <textarea
                  cols={3}
                  type="text"
                  className=" py-1 rounded-md w-full"
                />
              </div>
              <h1>Product Image</h1>
              <div className="relative w-full">
                <div className="absolute top-0 left-0 bg-white p-2 rounded-md w-full text-center border-2 border-[#00BF63] border-dashed">
                  <h1 className=" text-xs">Upload image (png,jpg,jpeg)</h1>
                </div>

                <input type="file" className="py-1 rounded-md" />
              </div>
            </div>
            <div className="flex justify-center gap-3 px-3">
              <div className="text-[#013220] bg-white py-2 text-center rounded-md w-72 mb-5">
                Cancel
              </div>
              <div className="bg-[#013220] text-white py-2 text-center rounded-md w-72 mb-5">
                Update Post
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditMarketPlace;
