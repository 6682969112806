import React from "react";

const AddressForm = () => {
  return (
    <div>
      <div className="flex gap-3">
      <div>
            <h1>Flat, Appartment no</h1>
             <input
        type="search"
        id="default-search"
        class="block w-full py-3 md:ps-8 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 :bg-gray-700 :border-gray-600 :placeholder-gray-400 :text-white :focus:ring-blue-500 :focus:border-blue-500"
        required
      />
        </div>
        <div>
            <h1>Street, Area etc</h1>
             <input
        type="search"
        id="default-search"
        class="block w-full p-3 md:ps-8 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 :bg-gray-700 :border-gray-600 :placeholder-gray-400 :text-white :focus:ring-blue-500 :focus:border-blue-500"
        required
      />
        </div>
      </div>
      <div className="flex gap-3">
      <div>
            <h1>Address line 1</h1>
             <input
        type="search"
        id="default-search"
        class="block w-full p-3 md:ps-8 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 :bg-gray-700 :border-gray-600 :placeholder-gray-400 :text-white :focus:ring-blue-500 :focus:border-blue-500"
        required
      />
        </div>
        <div>
            <h1>Address line 2</h1>
             <input
        type="search"
        id="default-search"
        class="block w-full p-3 md:ps-8 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 :bg-gray-700 :border-gray-600 :placeholder-gray-400 :text-white :focus:ring-blue-500 :focus:border-blue-500"
        required
      />
        </div>
      </div>
      <div className="flex gap-3">
      <div>
            <h1>City</h1>
             <input
        type="search"
        id="default-search"
        class="block w-full p-3 md:ps-8 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 :bg-gray-700 :border-gray-600 :placeholder-gray-400 :text-white :focus:ring-blue-500 :focus:border-blue-500"
        required
      />
        </div>
        <div>
            <h1>State</h1>
             <input
        type="search"
        id="default-search"
        class="block w-full p-3 md:ps-8 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 :bg-gray-700 :border-gray-600 :placeholder-gray-400 :text-white :focus:ring-blue-500 :focus:border-blue-500"
        required
      />
        </div>
      </div>
      <div className="flex gap-3">
      <div>
            <h1>Pincode</h1>
             <input
        type="search"
        id="default-search"
        class="block w-full p-3 md:ps-8 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 :bg-gray-700 :border-gray-600 :placeholder-gray-400 :text-white :focus:ring-blue-500 :focus:border-blue-500"
        required
      />
        </div>
        <div>
            <h1>Country</h1>
             <input
        type="search"
        id="default-search"
        class="block w-full p-3 md:ps-8 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 :bg-gray-700 :border-gray-600 :placeholder-gray-400 :text-white :focus:ring-blue-500 :focus:border-blue-500"
        required
      />
        </div>
      </div>
    </div>
  );
};

export default AddressForm;
