import React from "react";

const Toggle = ({setToggle,toggle}) => {
  return (
    <div className="px-1 md:px-10 py-2 md:py-5 md:mb-0">
    <nav className="flex w-full justify-center mb-5 text-gray-500">
      <h1
        onClick={(e) => setToggle((prev) => (prev =true))}
        className={` text-sm  md:text-xl w-1/2 text-center ${
          toggle === true
            ? "border-b-2 md:border-b-4 border-[#013220] text-black"
            : "border-b-2"
        }`}
      >
        My Listings
      </h1>
      <h1
        onClick={(e) => setToggle((prev) => (prev =false))}
        className={`text-sm md:text-xl w-1/2 text-center ${
          toggle === false
            ? "border-b-2 md:border-b-4 border-[#013220] text-black"
            : "border-b-2"
        }`}
      >
        Marketplace
      </h1>
    </nav>
  </div>
  );
};

export default Toggle;
