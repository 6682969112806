import React, { useEffect, useState } from "react";
import { NavbarDefault } from "../../Components/Home/Navbar";
import { MdOutlineDateRange } from "react-icons/md";
import { CiShare2 } from "react-icons/ci";
import BlogSideBar from "../../Components/Blog/BlogSideBar";
import Footer from "../../Components/Home/Footer";
import BlogsNews from "../../Components/Home/BlogsNews";
import { baseUrl } from "../../apis/constants";
import { useParams } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";

const FullBlog = () => {
  const params = useParams();

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: blog?.title,
          text: blog?.description,
          url: window.location.href,
        })
        .then(() => console.log("Content shared successfully!"))
        .catch((error) => console.error("Error sharing content: ", error));
    } else {
      alert("Sharing is not supported in your browser.");
    }
  };

  const [blog, setBlog] = useState({});

  const getBlog = () => {
    const config = {
      method: "get",
      url: `${baseUrl}blogs/${params.id}`,
      // headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => setBlog(res.data.data))
      .catch((err) => err);
  };

  useEffect(() => {
    getBlog();
  }, []);

  return (
    <div className=" bg-[#F5F5F5] ">
      <NavbarDefault />
      {/* <div className="flex items-center justify-between px-2 md:px-10 py-3">
         <div>
          <FaArrowLeft className=" text-2xl" />
        </div> 
        <div className="flex gap-2">
          <input
            className="block p-4 bg-[#F5F5F5] md:ps-8 text-sm text-gray-900 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 :bg-gray-700 :border-gray-600 :placeholder-gray-400 :text-white :focus:ring-blue-500 :focus:border-blue-500"
            type="text"
            placeholder="Search keyword"
          />
          <button
            type="submit"
            className="text-white bg-[#013220] hover:bg-[#00BF63] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 :bg-blue-600 :hover:bg-blue-700 :focus:ring-blue-800"
          >
            <IoSearch className="text-lg" />
          </button>
        </div>
      </div>*/}
      <div className="flex md:flex-row flex-col w-full px-2 md:px-10 mb-10 mt-6">
        <div className="md:w-2/3">
          <img
            src={blog.image}
            alt="gefmart-blog"
            style={{ minWidth: "100%" }}
          />
          <div className="flex w-full justify-between py-1">
            <p className="flex items-center gap-1 mt-2 text-center">
              <MdOutlineDateRange />
              <p>{dayjs(blog.createdAt).format("DD-MMM-YYYY")}</p>
            </p>
            <p
              className="flex gap-1"
              style={{ cursor: "pointer" }}
              onClick={handleShare}
            >
              <p className="flex items-center gap-1">
                <CiShare2 />
                <p>Share</p>
              </p>
            </p>
          </div>
          <div>
            <h1 className=" text-xl pt-1">{blog.title}</h1>
            <p className=" text-gray-400">{blog?.content}</p>
          </div>
        </div>
        <div className="hidden md:block md:w-1/3">
          <BlogSideBar />
        </div>
        <div className="mt-3 md:hidden">
          <BlogsNews />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FullBlog;
