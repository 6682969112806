import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

import { getAllBrandsUrl } from "../../apis/constants";
import styles from "./brands.module.css";
import { NavbarDefault } from "../../Components/Home/Navbar";

export default function Brands() {
  const accessToken = Cookies.get("gefmartUserToken");

  const navigate = useNavigate();

  const [brands, setBrands] = useState([]);

  const getAllBrands = () => {
    const config = {
      method: "post",
      url: getAllBrandsUrl,
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => setBrands(res.data.data))
      .catch((err) => err);
  };

  useEffect(() => {
    getAllBrands();
  }, []);

  return (
    <>
      <NavbarDefault />
      <div className={styles.container}>
        {brands.map((item, index) => (
          <div className={styles.singleItem} key={index}>
            <div
              className=" h-28 w-28 cursor-pointer"
              onClick={() =>
                navigate({ pathname: "/products" }, { state: item.name })
              }
            >
              <img
                className="object-cover max-w-24 lg:max-w-max lg:w-full h-full rounded-lg"
                src={item?.image}
                alt=""
              />
            </div>
            <h3 className="text-log mt-1" style={{ fontWeight: 600 }}>
              {item.name}
            </h3>
          </div>
        ))}
      </div>
    </>
  );
}
