import BackIcon from "../BackIcon";
import GoBack from "../GoBack";
import Search from "../Search";

export default function GoBackAndSearch({
  placeholder,
  setSearchKeyword,
  searchKeyword,
  title,
}) {
  return (
    <div className="goBackAndSearch">
      {/* <BackIcon title={title} />  */}
      <Search
        placeholder={placeholder}
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
      />
    </div>
  );
}
