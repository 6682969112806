import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { FaPen } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineStorefront } from "react-icons/md";
import AddMarketPlace from "./AddMarketPlace";
import EditMarketPlace from "./EditMarketPlace";

const MyListings = () => {
  const [isOpen, setOpen] = useState(false);
  const [isEdit, setEdit] = useState(false);

  return (
    <div className="flex flex-col gap-3  md:grid grid-cols-3 md:gap-4 md:px-10 px-2">
      <div
        onClick={() => setOpen(true)}
        className=" border-4 border-dotted w-96 flex flex-col justify-center items-center p-10"
      >
        <div>
          <FaPlus />
        </div>
        <div>Add Marketlace</div>
      </div>
      <div className="w-96 flex p-3 border shadow-md gap-2 rounded-md">
        <div className=" w-6/12">
          <img
            src="https://s3-alpha-sig.figma.com/img/d12b/e1a5/74bd067ac769692e592e48b377e0cc74?Expires=1713139200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=fvEijQLYRtXaR6eT9VsGRyJMLnhB2ZAuBlOcV3elQConpwG~gA1HrVe8iEdnPZtXCXFfwvZMHZapQXnCfTJ-qXcyQdsonPPNt2gxjO~Kckqh7jkVuzkYlSLOumVpavtUpx1ofbIzKP6ey9H7vwjQhhM~jkT3fbYU8ciyiRoD56jutUCEphwL4Wo4Gaq-5a2SD~qmhNAgxH-H75HsDV2xeCpc6G5b776qJ0IjMqqPWdvaDnF5gNPZALM00IPQp-S1LqUYKmypZdlks7Hu33Ck841zgd1Co2TQE56VaGAv~ah3yV1dyn2tJxNhJxlyVjR7xoaDYDEsL90rC24pRrMEjQ__"
            alt=""
            className=" w-44 object-cover rounded-md"
          />
        </div>
        <div>
          <div className="flex w-full justify-between gap-4">
            <h1 className="text-[#013220] text-lg mr-1">Havells Lights</h1>
            <div className=" bg-[#013220] text-white p-2 rounded-md text-sm font-bold">
              <FaPen />
            </div>
          </div>
          <div className="flex gap-1 mt-1">
            <p>
              <IoLocationOutline className="text-[#00BF63]" />
            </p>
            <p className=" text-sm">Ernakulam, Kerala</p>
          </div>

          <div className="flex gap-1">
            <MdOutlineStorefront className="text-[#00BF63]" />
            <p className=" text-sm">Nizam Agencies</p>
          </div>
          <p className=" text-lg font-medium text-[#00BF63] mt-2">
            Rs:104/per piece
          </p>
          <span className=" text-sm text-slate-400">12:53pm, 5 Jan 2024</span>
        </div>
      </div>
      <div className="w-96 flex p-3 border shadow-md gap-2 rounded-md">
        <div className=" w-6/12">
          <img
            src="https://s3-alpha-sig.figma.com/img/d12b/e1a5/74bd067ac769692e592e48b377e0cc74?Expires=1713139200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=fvEijQLYRtXaR6eT9VsGRyJMLnhB2ZAuBlOcV3elQConpwG~gA1HrVe8iEdnPZtXCXFfwvZMHZapQXnCfTJ-qXcyQdsonPPNt2gxjO~Kckqh7jkVuzkYlSLOumVpavtUpx1ofbIzKP6ey9H7vwjQhhM~jkT3fbYU8ciyiRoD56jutUCEphwL4Wo4Gaq-5a2SD~qmhNAgxH-H75HsDV2xeCpc6G5b776qJ0IjMqqPWdvaDnF5gNPZALM00IPQp-S1LqUYKmypZdlks7Hu33Ck841zgd1Co2TQE56VaGAv~ah3yV1dyn2tJxNhJxlyVjR7xoaDYDEsL90rC24pRrMEjQ__"
            alt=""
            className=" w-44 object-cover rounded-md"
          />
        </div>
        <div>
          <div className="flex w-full justify-between gap-4">
            <h1 className="text-[#013220] text-lg mr-1">Havells Lights</h1>
            <div
              onClick={(e) => setEdit(true)}
              className=" bg-[#013220] text-white p-2 rounded-md text-sm font-bold"
            >
              <FaPen />
            </div>
          </div>
          <div className="flex gap-1 mt-1">
            <p>
              <IoLocationOutline className="text-[#00BF63]" />
            </p>
            <p className=" text-sm">Ernakulam, Kerala</p>
          </div>

          <div className="flex gap-1">
            <MdOutlineStorefront className="text-[#00BF63]" />
            <p className=" text-sm">Nizam Agencies</p>
          </div>
          <p className=" text-lg font-medium text-[#00BF63] mt-2">
            Rs:104/per piece
          </p>
          <span className=" text-sm text-slate-400">12:53pm, 5 Jan 2024</span>
        </div>
      </div>
      <div className="w-96 flex p-3 border shadow-md gap-2 rounded-md">
        <div className=" w-6/12">
          <img
            src="https://s3-alpha-sig.figma.com/img/d12b/e1a5/74bd067ac769692e592e48b377e0cc74?Expires=1713139200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=fvEijQLYRtXaR6eT9VsGRyJMLnhB2ZAuBlOcV3elQConpwG~gA1HrVe8iEdnPZtXCXFfwvZMHZapQXnCfTJ-qXcyQdsonPPNt2gxjO~Kckqh7jkVuzkYlSLOumVpavtUpx1ofbIzKP6ey9H7vwjQhhM~jkT3fbYU8ciyiRoD56jutUCEphwL4Wo4Gaq-5a2SD~qmhNAgxH-H75HsDV2xeCpc6G5b776qJ0IjMqqPWdvaDnF5gNPZALM00IPQp-S1LqUYKmypZdlks7Hu33Ck841zgd1Co2TQE56VaGAv~ah3yV1dyn2tJxNhJxlyVjR7xoaDYDEsL90rC24pRrMEjQ__"
            alt=""
            className=" w-44 object-cover rounded-md"
          />
        </div>
        <div>
          <div className="flex w-full justify-between gap-4">
            <h1 className="text-[#013220] text-lg mr-1">Havells Lights</h1>
            <div className=" bg-[#013220] text-white p-2 rounded-md text-sm font-bold">
              <FaPen />
            </div>
          </div>
          <div className="flex gap-1 mt-1">
            <p>
              <IoLocationOutline className="text-[#00BF63]" />
            </p>
            <p className=" text-sm">Ernakulam, Kerala</p>
          </div>

          <div className="flex gap-1">
            <MdOutlineStorefront className="text-[#00BF63]" />
            <p className=" text-sm">Nizam Agencies</p>
          </div>
          <p className=" text-lg font-medium text-[#00BF63] mt-2">
            Rs:104/per piece
          </p>
          <span className=" text-sm text-slate-400">12:53pm, 5 Jan 2024</span>
        </div>
      </div>
      {isOpen && <AddMarketPlace isOpen={isOpen} setOpen={setOpen} />}
      {isEdit && <EditMarketPlace isEdit={isEdit} setEdit={setEdit} />}
    </div>
  );
};

export default MyListings;
