import { IoChevronBackCircleSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import BackIcon from "../../Components/BackIcon";

export default function GoBack({ title }) {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        padding: "0 10px",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={() => navigate(-1)}
    >
      <IoChevronBackCircleSharp style={{ marginRight: 5, fontSize: "20px" }} />
      <h5>{title}</h5>
    </div>
    // <div className="flex gap-5 items-center md:py-7 py-3">
    //   <BackIcon />
    //   <h1 className="text-xl">Market Place</h1>
    // </div>
  );
}
