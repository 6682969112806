import {
  CallOutlined,
  LocationOnOutlined,
  MailOutline,
} from "@mui/icons-material";

import BackIcon from "../../Components/BackIcon";
import { primaryColor, secondaryColor } from "../../constants/constants";
import { fontSize } from "@mui/system";

export default function Help() {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      boxShadow:
        "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
      borderRadius: "10px",
      padding: "20px",
      marginBottom: "20px",
    },
    icon: {
      margin: "auto",
      color: secondaryColor,
      fontSize: "45px",
    },
    headingContainer: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      color: primaryColor,
    },
  };

  return (
    <div style={{ padding: "20px" }}>
      <BackIcon page={"Help & Support"} />
      <div>
        <div style={styles.container}>
          <div style={styles.headingContainer}>
            <LocationOnOutlined style={styles.icon} />
            <span style={{ margin: "10px auto" }}>Locate Us</span>
          </div>
          Geften Electrical Solutions LLP 39/2475-B1, Suite 716, LR Towers,
          SJRRA 104, South Janatha Road, Palarivattom PO, Kochi Ernamkulam
          (Dist) Kerala-682025
        </div>
        <div style={styles.container}>
          <div style={styles.headingContainer}>
            <MailOutline style={styles.icon} />
            <span style={{ margin: "10px auto" }}>Mail Us</span>
          </div>
          Locate Us Geften Electrical Solutions LLP 39/2475-B1, Suite 716, LR
          Towers, SJRRA 104, South Janatha Road, Palarivattom PO, Kochi
          Ernamkulam (Dist) Kerala-682025 Mail Us gefmartsupport@geften.com Call
          or Chat +91 8921951010\n9.00am to 7.00pm (Mon-Sat)
        </div>
        <div style={styles.container}>
          <div style={styles.headingContainer}>
            <CallOutlined style={styles.icon} />
            <span style={{ margin: "10px auto" }}>Call or Chat</span>
          </div>
          +91 8921951010 <br />
          9.00am to 7.00pm (Mon-Sat)
        </div>
      </div>
    </div>
  );
}
