import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./warehouse.module.css";
import Search from "../../Components/Search";
import BackIcon from "../../Components/BackIcon";
import Footer from "../../Components/Home/Footer";
import AddToCart from "../../Components/AddToCart";
import dummyPdtImg from "../../assets/App-logo.png";
import { getProductsUrl } from "../../apis/constants";
import { UserStates } from "../../context/UserContext";
import { NavbarDefault } from "../../Components/Home/Navbar";
import PaginationBtns from "../../Components/paginationBtns";
import MobileMenuBar from "../../Components/Home/MobileMenuBar";

export default function Warehouse() {
  const navigate = useNavigate();

  const { accessToken, getCart, currentPage, setCurrentPage } =
    useContext(UserStates);

  const sessionPageNo = sessionStorage.getItem("product-page-no");
  
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState([]);
  const [totalPages, setTotalPages] = useState("");

  const getProducts = (page) => {
    const config = {
      method: "post",
      url: `${getProductsUrl}${
        page !== undefined ? page : sessionPageNo !== null ? sessionPageNo : "1"
      }`,
      data: { search },
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => {
        setTotalPages(res.data.totalPages);
        sessionStorage.setItem("product-page-no", res.data.currentPage);
        setCurrentPage(Number(res.data.currentPage));
        setProducts(res.data.data);
      })
      .catch((err) => {
        setProducts([]);
      });
  };

  useEffect(() => {
    getCart();
    getProducts(currentPage);
  }, []);

  useEffect(() => {
    getProducts(1);
  }, [search]);

  return (
    <div className="bg-[#F5F5F5] w-screen min-h-screen">
      <NavbarDefault />

      <BackIcon page={"Warehouse"} marginTop={"15px"} marginLeft={"15px"} />
      <Search placeholder={"products"} setSearchKeyword={setSearch} />

      <div className={`p-3 md:px-10 md:mb-7 flex gap-3 flex-wrap`}>
        {products.length === 0 ? (
          <div
            style={{
              borderRadius: 5,
              marginBottom: "5px",
              textAlign: "center",
              padding: "100px 0",
              // background: "white",
              width: "100%",
            }}
          >
            No products
          </div>
        ) : (
          products?.map((product) => (
            <div
              className={styles.itemContainer}
              onClick={() => navigate(`${product._id}`)}
            >
              <div>
                <img
                  src={product.images[0] || dummyPdtImg}
                  alt={`gefmart ${product.name}`}
                  className={styles.productImg}
                />
                <h3>{product.name}</h3>
              </div>
              <AddToCart id={product._id} />
            </div>
          ))
        )}
      </div>

      <PaginationBtns
        totalPages={totalPages}
        getItems={getProducts}
        search={search}
        style={{ marginBottom: "65px" }}
      />
      <MobileMenuBar />
      <Footer />
    </div>
  );
}
