import axios from "axios";
import { Image } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";

import BackIcon from "../../Components/BackIcon";
import Footer from "../../Components/Home/Footer";
import { getProductById } from "../../apis/products";
import { getProductsUrl } from "../../apis/constants";
import { UserStates } from "../../context/UserContext";
import ProductContext from "../../utils/contexts/ProductContext";
import MobileMenuBarCart from "../../Components/ProductDetail/MenuBarCart";
import HeadDescription from "../../Components/ProductDetail/HeadDescription";

import styles from "./productdetails.module.css";

function ProductDetail() {
  const params = useParams();
  const navigate = useNavigate();

  const { accessToken } = useContext(UserStates);

  const [data, setData] = useState();
  const [products, setProducts] = useState([]);

  const getProducts = (data) => {
    const config = {
      method: "post",
      url: `${getProductsUrl}1`,
      data: { search: data?.item_name?.slice(0, 5) },
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => setProducts(res.data.data))
      .catch((err) => setProducts([]));
  };

  useEffect(() => {
    async function fetch() {
      const response = await getProductById(params.id);
      console.log("response", response);
      setData(response?.data?.data);
      getProducts(response?.data?.data);
    }
    fetch();
  }, []);

  return (
    <div className="md:px-10 px-3 +relative">
      <BackIcon page={"Products"} marginTop={"10px"} />
      <div className="w-screen shadow-lg">{/* <NavbarDefault /> */}</div>
      <div
        className="navigationSearch"
        onClick={() => navigate("/warehouse")}
        style={{ margin: "10px auto", width: "100%" }}
      >
        Search for products
      </div>
      <ProductContext.Provider value={data}>
        <HeadDescription />
        {/* <ProductDetails /> */}
        {/* <div className="p-2 mb-16 md:mb-2">
          <h1 className=" text-lg py-2">Latest</h1>
          <SimilarProducts />
        </div> */}

        <Footer />
        <MobileMenuBarCart id={data?._id} />
      </ProductContext.Provider>
      <div style={{ marginBottom: "70px" }}>
        <h2 style={{ margin: "10px", fontSize: "16px" }}>Similar Products</h2>
        <div style={{ display: "flex" }}>
          {products?.map((item) => (
            <div
              key={item._id}
              className={`${styles.itemContainer} border shadow-md gap-2 rounded-md cursor-pointer`}
              onClick={() => navigate(`/warehouse/${item._id}`)}
            >
              <Image src={item.images[0]} className={styles.image} />
              <h6>{item.name}</h6>
              <span>Rate ₹{item?.rate}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProductDetail;
