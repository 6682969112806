import React from "react";
import { IoStar } from "react-icons/io5";
import { Link } from "react-router-dom";

function LatestItem({ product }) {
  return (
    <Link
      to={`/marketplace/${product._id}`}
      className="w-[305px]  bg-[#FAFAFA] box_shadow  p-2 my-5 mb-9"
    >
      <div className="flex justify-center">
        <div
          className={`flex justify-center w-full ${
            !product.images[0] && "bg-[#CCCCCC]  h-60"
          }`}
        >
          <img
            src={product.images[0]}
            className=" m-1"
            alt="latest gefmart"
            style={{ width: "100%", height: "150px", objectFit: "cover" }}
          />
        </div>
      </div>
      <div className="poppins-medium">
        <p className="text-[#252525] truncate my-2">{product.name}</p>
        <p className="text-[14px] leading-[21px]">{product.name}</p>
        {/* <div className="flex mt-6">
          <IoStar className="text-[#F2DA5D] text-xl mx-1" />
          <IoStar className="text-[#F2DA5D] text-xl mx-1" />
          <IoStar className="text-[#F2DA5D] text-xl mx-1" />
          <IoStar className="text-[#F2DA5D] text-xl mx-1" />
          <IoStar className="text-[#F2DA5D] text-xl mx-1" />
        </div> */}
        <p className="poppins-medium text-[#013220] text-[24px] font-[500] mt-2">
          ₹ {product.retail_price}/piece
        </p>
        {/* <p className='line-through text-[#CCCCCC] text-[16px]'>₹2,459</p> */}
      </div>
      {/* <button className="text-center p-3 rounded-md bg-[#013220] text-white w-full mt-4 mb-2">
        Add to cart
      </button> */}
    </Link>
  );
}

export default LatestItem;
