import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Button } from "@mui/material";
import { useContext } from "react";

import { UserStates } from "../../context/UserContext";

export default function PaginationBtns({
  totalPages,
  getItems,
  search,
  style,
}) {
  const { currentPage } = useContext(UserStates);

  const handlePrevClick = () => {
    getItems(currentPage - 1, search);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
  };

  const handleNextClick = () => {
    getItems(currentPage + 1, search);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
  };

  return (
    <div className="navBtnsWrapper" style={style}>
      <Button
        onClick={handlePrevClick}
        disabled={currentPage === 1}
        startIcon={<CaretLeftOutlined />}
        className="muiNavigationBtn"
      />

      <h5 style={{ margin: "0 8px" }}>
        Page {currentPage} of {totalPages}
      </h5>

      <Button
        onClick={handleNextClick}
        disabled={currentPage === totalPages}
        endIcon={<CaretRightOutlined />}
        className="muiNavigationBtn"
      />
    </div>
  );
}
