import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { getUserDetailsUrl } from "../../apis/constants";
import { UserStates } from "../../context/UserContext";
import BackIcon from "../../Components/BackIcon";
import { Image, Select } from "antd";

import styles from "./profile.module.css";

export default function Profile() {
  const { accessToken } = useContext(UserStates);

  const userId = localStorage.getItem("userId");

  const [user, setUser] = useState(null);

  // get user details
  const getUserDetails = () => {
    const config = {
      method: "get",
      url: `${getUserDetailsUrl}${userId}`,
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => {
        setUser(res.data.data);
        localStorage.setItem("profile-pic", res.data.data.image);
      })
      .catch((err) => err);
  };

  // update user details
  const updateUserDetails = () => {
    const config = {
      method: "patch",
      url: `${getUserDetailsUrl}${userId}`,
      data: user,
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => setUser(res.data.data))
      .catch((err) => err);
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      <BackIcon page={"Edit Profile"} />
      {user !== null && (
        <div className={styles.container}>
          <Image
            src={user?.image}
            style={{
              width: "150px",
              height: "150px",
              borderRadius: "75px",
              objectFit: "cover",
            }}
          />
          <div className={styles.itemContainer}>
            <span>Shop Owner Name</span>
            <input
              type="text"
              name="name"
              value={user?.name}
              onChange={(e) => setUser({ ...user, name: e.target.value })}
            />
          </div>
          <div className={styles.itemContainer}>
            <span>Place</span>
            <input
              type="text"
              name="address"
              value={user?.address?.place}
              onChange={(e) =>
                setUser({
                  ...user,
                  address: {
                    ...user.address,
                    place: e.target.value,
                  },
                })
              }
            />
          </div>
          <div className={styles.itemContainer}>
            <span>Business Name</span>
            <input
              type="text"
              name="business_name"
              value={user?.business_name}
              onChange={(e) =>
                setUser({ ...user, business_name: e.target.value })
              }
            />
          </div>
          <div className={styles.itemContainer}>
            <button>Select from map</button>
          </div>
          <div className={styles.itemContainer}>
            <span>Address</span>
            {/* <input
              type="text"
              name="address"
              value={user?.address?.landmark}
              onChange={(e) => setUser({...user,})}
            /> */}
            <textarea
              style={{ width: "100%", height: "100px" }}
              type="text"
              name="address"
              value={user?.address?.landmark}
              //   onChange={(e) => setUser({ ...user })}
            />
          </div>
          <div className={styles.itemContainer}>
            <span>GST No</span>
            <input
              type="text"
              name="gst"
              value={user?.gst}
              onChange={(e) => setUser({ ...user, gst: e.target.value })}
            />
          </div>
          <div className={styles.itemContainer}>
            <span>Phone Number</span>
            <input
              type="text"
              name="number"
              value={user?.number}
              onChange={(e) => setUser({ ...user, number: e.target.value })}
            />
          </div>
          <div className={styles.itemContainer}>
            <span>Alternative Nubmer</span>
            <input
              type="text"
              name="alternative_number"
              value={user?.alternative_number}
              onChange={(e) =>
                setUser({ ...user, alternative_number: e.target.value })
              }
            />
          </div>
          <div className={styles.itemContainer}>
            <span>State</span>
            <input
              type="text"
              name="address"
              value={user?.address?.state}
              onChange={(e) =>
                setUser({
                  ...user,
                  address: {
                    ...user.address,
                    state: e.target.value,
                  },
                })
              }
            />
          </div>
          <div className={styles.itemContainer}>
            <span>District</span>
            <input
              type="text"
              name="address"
              value={user?.address?.district}
              onChange={(e) =>
                setUser({
                  ...user,
                  address: {
                    ...user.address,
                    district: e.target.value,
                  },
                })
              }
            />
          </div>
          <div className={styles.itemContainer}>
            <span>Pin code</span>
            <input
              type="text"
              name="address"
              value={user?.address?.pincode}
              onChange={(e) =>
                setUser({
                  ...user,
                  address: {
                    ...user.address,
                    pincode: e.target.value,
                  },
                })
              }
            />
          </div>
          <div className={styles.itemContainer}>
            <span>Are you a?</span>
            <select
              value={user.type}
              onChange={(e) => {
                setUser({ ...user, type: e.target.value });
                console.log("event : ", e.target.value);
              }}
            >
              <option value={5} key={5}>
                Retailer
              </option>
              <option value={6} key={6}>
                Distributor
              </option>
              <option value={4} key={4}>
                Channel Partner Gold
              </option>
              <option value={7} key={7}>
                Channel Partner Diamond
              </option>
            </select>
          </div>
          <div className={styles.itemContainer}>
            <button onClick={updateUserDetails}>Save</button>
          </div>
        </div>
      )}
    </div>
  );
}
