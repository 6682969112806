import React, { useContext } from "react";

import dummyPdtImg from "../../assets/App-logo.png";
import { baseUrl } from "../../apis/constants";
import { UserStates } from "../../context/UserContext";
import axios from "axios";
import { DeleteForever } from "@mui/icons-material";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

const ProductCard = ({ product }) => {
  const navigate = useNavigate();

  const { accessToken, userId, setCart, cart, getCart } =
    useContext(UserStates);

  const addToCart = (id, type, quantity) => {
    const config = {
      method: "post",
      url: `${baseUrl}carts`,
      data: {
        productId: id,
        userId,
        quantity: type === "input" ? quantity : type === "plus" ? 1 : -1,
      },
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => setCart(res.data.data))
      .catch((err) => err);
  };

  const removeFromCart = () => {
    const config = {
      method: "patch",
      url: `${baseUrl}delete-cart/${product?._id}`,
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => {
        getCart();
        message.success("Item removed from cart");
      })
      .catch((err) => err);
  };

  const handleQuantityChange = (productId, newQuantity, quantity) => {
    const updatedCart = cart.map((product) =>
      product.product._id === productId
        ? { ...product, quantity: newQuantity }
        : product
    );
    setCart(updatedCart);
    addToCart(productId, "input", newQuantity - quantity);
  };

  return (
    <div className=" md:flex gap-4 bg-white p-2 md:p-3 md:max-h-44">
      <div
        className="flex gap-2 md:w-full cursor-pointer"
        onClick={() => navigate(`/warehouse/${product?.product?._id}`)}
      >
        <div className="md:w-44  w-36">
          <img
            src={product.product.images[0] || dummyPdtImg}
            alt={`Gefmart ${product.product.name}`}
            className="object-cover m-auto"
            style={{ width: "100px", height: "100px", borderRadius: "6px" }}
          />
        </div>
        <div className="w-3/4 lg:w-full">
          <div>
            <h1>{product.product.name}</h1>
            {/* <p className=" text-gray-500">{product.product.description}</p> */}
            <div className="md:hidden text-xl font-semibold mb-2">
              ₹{product.product.rate} /-Per pc
            </div>
          </div>
          <div className="cartQuantityAndDeleteIcon">
            <div className="flex border text-center justify-center items-center p-1 gap-2 rounded-md max-w-44 pc-price-small">
              <div
                className="bg-gray-300 px-6 py-1 rounded-md text-lg cursor-pointer"
                onClick={() => addToCart(product.product._id, "minus")}
              >
                -
              </div>
              <input
                className="text-black text-xl px-3"
                value={product.quantity}
                onChange={(e) =>
                  handleQuantityChange(
                    product.product._id,
                    e.target.value,
                    product.quantity
                  )
                }
              />
              <div
                className=" bg-[#013220] text-white px-6 py-1 rounded-md text-lg cursor-pointer"
                onClick={() => addToCart(product.product._id, "plus")}
              >
                +
              </div>
            </div>
            <DeleteForever onClick={removeFromCart} />
          </div>
        </div>
      </div>

      <div className="p-2 md:justify-center flex flex-col md:items-center">
        <div className="hidden md:block text-xl font-semibold mb-2">
          ₹{product.product.rate * product.quantity}
        </div>
        <div className="flex border text-center justify-center items-center p-1 gap-2 rounded-md max-w-44 lg:max-w-full pc-price-large">
          <div
            className="bg-gray-300 px-6 py-1 rounded-md text-lg cursor-pointer"
            onClick={() => addToCart(product.product._id, "minus")}
          >
            -
          </div>
          <div className="text-black text-xl px-3">{product.quantity}</div>
          <div
            className=" bg-[#013220] text-white px-6 py-1 rounded-md text-lg cursor-pointer"
            onClick={() => addToCart(product.product._id, "plus")}
          >
            +
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
