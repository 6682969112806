import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

export default function BackIcon({ page, marginTop, marginLeft }) {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
        cursor: "pointer",
        marginTop,
        marginLeft,
      }}
      onClick={() => navigate(-1)}
    >
      <FaArrowLeft className=" text-lg" style={{ marginRight: "6px" }} />
      <h5>{page}</h5>
    </div>
  );
}
