import React, { useState } from "react";
import { RiHome4Line, RiUserLine } from "react-icons/ri";
import { AiOutlineShop } from "react-icons/ai";

import { MdOutlineCategory } from "react-icons/md";
import { Link } from "react-router-dom";
import ProfileSider from "../Profile";

const MobileMenuBar = () => {
  const [showSider, setShowSider] = useState(false);
  return (
    <>
      {showSider && <ProfileSider setShowSider={setShowSider} />}
      <div className="md:hidden fixed bottom-0 left-0 w-full bg-white text-gray-400 h-16 flex items-center justify-around text-3xl px-4">
        <Link to={"/"}>
          <RiHome4Line />
        </Link>
        {/* <Link to={"/categories"}>
          <MdOutlineCategory />
        </Link> */}
        <Link to={"/warehouse"}>
          <img className="w-7" src="/Picture1.png" alt="" />
        </Link>
        <Link to={"/marketplace"}>
          <AiOutlineShop />
        </Link>
        <Link to={"#"} onClick={() => setShowSider(true)}>
          <RiUserLine />
        </Link>
      </div>
    </>
  );
};

export default MobileMenuBar;
