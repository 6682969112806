import React from "react";
import { FaPen } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineStorefront } from "react-icons/md";
import { IoMdCall } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";

const MarketPlace = () => {
  return (
    <div>
      <div className="flex justify-between md:px-10 px-2 py-2">
        <div className="flex gap-5">
          <div>
            <select name="" id="" className="bg-[#F5F5F5]">
              <option value="">Seller Type</option>
              <option value="">fdfdf</option>
            </select>
          </div>
          <div>
            <select name="" id="" className="bg-[#F5F5F5]">
              <option  value="">Related</option>
              <option value="">fdfdf</option>
            </select>
          </div>
        </div>
        <div>
          <select name="" id="" className="bg-[#F5F5F5]">
            <option value="">Add City</option>
            <option value="">fdfdf</option>
          </select>
        </div>
      </div>

      <div className=" flex flex-col gap-3 md:grid grid-cols-3 md:gap-4 md:px-10 items-center">
        <div className="w-96 p-3 border shadow-md gap-2 rounded-md">
          <div className="flex gap-2">
            <div className=" w-6/12">
              <img
                src="https://s3-alpha-sig.figma.com/img/d12b/e1a5/74bd067ac769692e592e48b377e0cc74?Expires=1713139200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=fvEijQLYRtXaR6eT9VsGRyJMLnhB2ZAuBlOcV3elQConpwG~gA1HrVe8iEdnPZtXCXFfwvZMHZapQXnCfTJ-qXcyQdsonPPNt2gxjO~Kckqh7jkVuzkYlSLOumVpavtUpx1ofbIzKP6ey9H7vwjQhhM~jkT3fbYU8ciyiRoD56jutUCEphwL4Wo4Gaq-5a2SD~qmhNAgxH-H75HsDV2xeCpc6G5b776qJ0IjMqqPWdvaDnF5gNPZALM00IPQp-S1LqUYKmypZdlks7Hu33Ck841zgd1Co2TQE56VaGAv~ah3yV1dyn2tJxNhJxlyVjR7xoaDYDEsL90rC24pRrMEjQ__"
                alt=""
                className=" w-44 object-cover rounded-md"
              />
            </div>
            <div>
              <div className="flex w-full justify-between gap-4">
                <h1 className="text-[#013220] text-lg mr-1">Havells Lights</h1>
              </div>
              <div className="flex gap-1 mt-1">
                <p>
                  <IoLocationOutline className="text-[#00BF63]" />
                </p>
                <p className=" text-sm">Ernakulam, Kerala</p>
              </div>

              <div className="flex gap-1">
                <MdOutlineStorefront className="text-[#00BF63]" />
                <p className=" text-sm">Nizam Agencies</p>
              </div>
              <p className=" text-lg font-medium text-[#00BF63] mt-2">
                Rs:104/per piece
              </p>
              <span className=" text-sm text-slate-400">
                12:53pm, 5 Jan 2024
              </span>
            </div>
          </div>
          <div className="flex w-full justify-between mt-2">
            <div className="flex items-center bg-[#013220] text-white px-10 rounded-md py-1 gap-2">
              <IoMdCall />
              <p>Call</p>
            </div>
            <div className="flex items-center text-[#013220] border border-[#013220]  bg-white px-6 rounded-md py-1 gap-2">
              <FaWhatsapp />
              <p>Request Price</p>
            </div>
          </div>
        </div>
        <div className="w-96 p-3 border shadow-md gap-2 rounded-md">
          <div className="flex gap-2">
            <div className=" w-6/12">
              <img
                src="https://s3-alpha-sig.figma.com/img/d12b/e1a5/74bd067ac769692e592e48b377e0cc74?Expires=1713139200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=fvEijQLYRtXaR6eT9VsGRyJMLnhB2ZAuBlOcV3elQConpwG~gA1HrVe8iEdnPZtXCXFfwvZMHZapQXnCfTJ-qXcyQdsonPPNt2gxjO~Kckqh7jkVuzkYlSLOumVpavtUpx1ofbIzKP6ey9H7vwjQhhM~jkT3fbYU8ciyiRoD56jutUCEphwL4Wo4Gaq-5a2SD~qmhNAgxH-H75HsDV2xeCpc6G5b776qJ0IjMqqPWdvaDnF5gNPZALM00IPQp-S1LqUYKmypZdlks7Hu33Ck841zgd1Co2TQE56VaGAv~ah3yV1dyn2tJxNhJxlyVjR7xoaDYDEsL90rC24pRrMEjQ__"
                alt=""
                className=" w-44 object-cover rounded-md"
              />
            </div>
            <div>
              <div className="flex w-full justify-between gap-4">
                <h1 className="text-[#013220] text-lg mr-1">Havells Lights</h1>
              </div>
              <div className="flex gap-1 mt-1">
                <p>
                  <IoLocationOutline className="text-[#00BF63]" />
                </p>
                <p className=" text-sm">Ernakulam, Kerala</p>
              </div>

              <div className="flex gap-1">
                <MdOutlineStorefront className="text-[#00BF63]" />
                <p className=" text-sm">Nizam Agencies</p>
              </div>
              <p className=" text-lg font-medium text-[#00BF63] mt-2">
                Rs:104/per piece
              </p>
              <span className=" text-sm text-slate-400">
                12:53pm, 5 Jan 2024
              </span>
            </div>
          </div>
          <div className="flex w-full justify-between mt-2">
            <div className="flex items-center bg-[#013220] text-white px-10 rounded-md py-1 gap-2">
              <IoMdCall />
              <p>Call</p>
            </div>
            <div className="flex items-center text-[#013220] border border-[#013220]  bg-white px-6 rounded-md py-1 gap-2">
              <FaWhatsapp />
              <p>Request Price</p>
            </div>
          </div>
        </div>
        <div className="w-96 p-3 border shadow-md gap-2 rounded-md">
          <div className="flex gap-2">
            <div className=" w-6/12">
              <img
                src="https://s3-alpha-sig.figma.com/img/d12b/e1a5/74bd067ac769692e592e48b377e0cc74?Expires=1713139200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=fvEijQLYRtXaR6eT9VsGRyJMLnhB2ZAuBlOcV3elQConpwG~gA1HrVe8iEdnPZtXCXFfwvZMHZapQXnCfTJ-qXcyQdsonPPNt2gxjO~Kckqh7jkVuzkYlSLOumVpavtUpx1ofbIzKP6ey9H7vwjQhhM~jkT3fbYU8ciyiRoD56jutUCEphwL4Wo4Gaq-5a2SD~qmhNAgxH-H75HsDV2xeCpc6G5b776qJ0IjMqqPWdvaDnF5gNPZALM00IPQp-S1LqUYKmypZdlks7Hu33Ck841zgd1Co2TQE56VaGAv~ah3yV1dyn2tJxNhJxlyVjR7xoaDYDEsL90rC24pRrMEjQ__"
                alt=""
                className=" w-44 object-cover rounded-md"
              />
            </div>
            <div>
              <div className="flex w-full justify-between gap-4">
                <h1 className="text-[#013220] text-lg mr-1">Havells Lights</h1>
              </div>
              <div className="flex gap-1 mt-1">
                <p>
                  <IoLocationOutline className="text-[#00BF63]" />
                </p>
                <p className=" text-sm">Ernakulam, Kerala</p>
              </div>

              <div className="flex gap-1">
                <MdOutlineStorefront className="text-[#00BF63]" />
                <p className=" text-sm">Nizam Agencies</p>
              </div>
              <p className=" text-lg font-medium text-[#00BF63] mt-2">
                Rs:104/per piece
              </p>
              <span className=" text-sm text-slate-400">
                12:53pm, 5 Jan 2024
              </span>
            </div>
          </div>
          <div className="flex w-full justify-between mt-2">
            <div className="flex items-center bg-[#013220] text-white px-10 rounded-md py-1 gap-2">
              <IoMdCall />
              <p>Call</p>
            </div>
            <div className="flex items-center text-[#013220] border border-[#013220]  bg-white px-6 rounded-md py-1 gap-2">
              <FaWhatsapp />
              <p>Request Price</p>
            </div>
          </div>
        </div>
        <div className="w-96 p-3 border shadow-md gap-2 rounded-md">
          <div className="flex gap-2">
            <div className=" w-6/12">
              <img
                src="https://s3-alpha-sig.figma.com/img/d12b/e1a5/74bd067ac769692e592e48b377e0cc74?Expires=1713139200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=fvEijQLYRtXaR6eT9VsGRyJMLnhB2ZAuBlOcV3elQConpwG~gA1HrVe8iEdnPZtXCXFfwvZMHZapQXnCfTJ-qXcyQdsonPPNt2gxjO~Kckqh7jkVuzkYlSLOumVpavtUpx1ofbIzKP6ey9H7vwjQhhM~jkT3fbYU8ciyiRoD56jutUCEphwL4Wo4Gaq-5a2SD~qmhNAgxH-H75HsDV2xeCpc6G5b776qJ0IjMqqPWdvaDnF5gNPZALM00IPQp-S1LqUYKmypZdlks7Hu33Ck841zgd1Co2TQE56VaGAv~ah3yV1dyn2tJxNhJxlyVjR7xoaDYDEsL90rC24pRrMEjQ__"
                alt=""
                className=" w-44 object-cover rounded-md"
              />
            </div>
            <div>
              <div className="flex w-full justify-between gap-4">
                <h1 className="text-[#013220] text-lg mr-1">Havells Lights</h1>
              </div>
              <div className="flex gap-1 mt-1">
                <p>
                  <IoLocationOutline className="text-[#00BF63]" />
                </p>
                <p className=" text-sm">Ernakulam, Kerala</p>
              </div>

              <div className="flex gap-1">
                <MdOutlineStorefront className="text-[#00BF63]" />
                <p className=" text-sm">Nizam Agencies</p>
              </div>
              <p className=" text-lg font-medium text-[#00BF63] mt-2">
                Rs:104/per piece
              </p>
              <span className=" text-sm text-slate-400">
                12:53pm, 5 Jan 2024
              </span>
            </div>
          </div>
          <div className="flex w-full justify-between mt-2">
            <div className="flex items-center bg-[#013220] text-white px-10 rounded-md py-1 gap-2">
              <IoMdCall />
              <p>Call</p>
            </div>
            <div className="flex items-center text-[#013220] border border-[#013220]  bg-white px-6 rounded-md py-1 gap-2">
              <FaWhatsapp />
              <p>Request Price</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between md:px-10 px-1 mt-5 ">
        <div>shows 10 / 100 results</div>
        <div>
          <div className="flex">
            <p className="text-[#CCCCCC] poppins-medium text-[14px] items-center cursor-pointer">
              Previous
            </p>
            <div className="flex mx-3 items-center">
              <p className="  cursor-pointer text-[14px] mx-3">1</p>
              <p className="text-[14px]  px-2 bg-[#013220] cursor-pointer text-white rounded-md">
                2
              </p>
            </div>
            <p className="text-[14px] underline cursor-pointer">Next</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketPlace;
