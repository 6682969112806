import Cookies from "js-cookie";
import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const token = Cookies.get("gefmartUserToken");
  const isLoggedIn = !!token;

  const loggedInRestrictedRoutes = ["/login"];
  const loggedOutRestrictedRoutes = ["/", "/products"];

  const restrictedRoutes = isLoggedIn
    ? loggedInRestrictedRoutes
    : loggedOutRestrictedRoutes;
  const currentRoute = window.location.pathname;
  const isRestrictedRoute = restrictedRoutes.includes(currentRoute);

  // Redirect logic
  if (isRestrictedRoute && isLoggedIn) {
    return <Navigate to="/" replace />;
  } else if (isRestrictedRoute && !isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
