import React, { useEffect, useState } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import Cookies from "js-cookie";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

import { getAllCategoriesUrl } from "../../apis/constants";
import Title from "./titles";

const Categories = () => {
  const navigate = useNavigate();

  const accessToken = Cookies.get("gefmartUserToken");

  const [data, setData] = useState([]);

  useEffect(() => {
    const getAllCategories = (type) => {
      const config = {
        method: "post",
        url: getAllCategoriesUrl,
        headers: { Authorization: accessToken },
      };

      axios(config)
        .then((res) => setData(res.data.data))
        .catch((err) => err);
    };

    if (accessToken !== undefined) {
      getAllCategories();
    }
  }, [accessToken]);

  return (
    <div className="flex flex-col gap-4">
      <Title header={"Categories"} url={"/categories"} />
      <div className="flex justify-start items-start w-full h-full gap-5 lg:gap-10 overflow-scroll lg:overflow-x-scroll">
        {data?.map((item, index) => (
          <div
            className="flex max-w-28 flex-col justify-center items-center w-full text-center cursor-pointer"
            key={index}
            onClick={() => navigate(`/marketplace/category/${item._id}`)}
          >
            <img
              className="rounded-full max-w-16 h-16 object-cover lg:max-w-28 lg:w-28 lg:h-28"
              src={item.image}
              alt="category img"
            />
            <h3 className="text-xs lg:text-sm text mt-2">{item?.name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Categories;
