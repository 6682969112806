import React, { useState, useEffect } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

import Title from "./titles";
import { getAllBlogs } from "../../apis/Blog";

const BlogsNews = () => {
  const [data, setData] = useState([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  };

  useEffect(() => {
    try {
      async function fetch() {
        const response = await getAllBlogs();
        setData(response.data.data);
      }
      fetch();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      <div className="bg-[#E1E6E4] p-4 md:p-10 mb-10 md:mb-0">
        <Title header={" Blogs & News"} url={"/blog"} />
        {/* <h1
          className="md:text-2xl font-normal mb-2"
          style={{ color: secondaryColor }}
        >
          Blogs & News
        </h1>
        <h5
          className="text-[#00BF63] flex justify-center items-center gap-2 cursor-pointer"
          onClick={() => navigate("/blog")}
        >
          show all <FaArrowRightLong className=" text-xs" />
        </h5> */}
        <div className="hidden sm:block">
          <Slider {...settings}>
            {data?.map((item) => (
              <div className="!flex !justify-center items-center">
                <div className="flex flex-col justify-center items-center rounded-md border w-40 h-56 sm:w-48 md:w-60 md:mr-1 md:h-80 lg:w-96 lg:h-96 bg-white p-2 lg:p-4 md:p-3">
                  <div className="md:h-3/4 w-full">
                    <img
                      className="object-cover w-full h-full rounded-t-md"
                      src={item?.image}
                      alt=""
                    />
                  </div>
                  <h3 className=" text-xs mb-1 md:text-lg lg:text-xl mt-1">
                    {item?.title}
                  </h3>
                  <button className="text-white bg-[#013220] md:text-md text-center items-center justify-center p-1 md:p-2 w-full rounded-md">
                    <div className="flex items-center gap-2 justify-center">
                      <Link to={`blog/${item?._id}`}>
                        <h1 className=" text-sm md:text-base">Read more </h1>{" "}
                      </Link>
                      <h5>
                        <FaArrowRightLong />
                      </h5>
                    </div>
                  </button>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="flex overflow-y-hidden overflow-x-scroll gap-2 sm:hidden mb-5">
          {data?.map((item) => (
            <div>
              <div className="flex flex-col items-center rounded-md border w-40 h-56 sm:w-48 md:mr-1 md:w-60 md:h-80 lg:w-96 lg:h-96 bg-white p-2 lg:p-4 md:p-3">
                <div className="h-3/4 w-full">
                  <img
                    className="object-cover w-full h-full rounded-t-md"
                    src={item?.image}
                    alt=""
                  />
                </div>
                <h3
                  className="text-xs mb-1 md:text-lg lg:text-xl mt-1"
                  style={{ marginTop: "5px" }}
                >
                  {item.title}
                </h3>
                <button className="text-white bg-[#013220] md:text-md text-center items-center justify-center p-1 md:p-2 w-full rounded-md">
                  <div className="flex items-center gap-2 justify-center">
                    <Link to={`blog/${item?._id}`}>
                      <h1 className="text-sm md:text-base">Read more </h1>{" "}
                    </Link>

                    <h5>
                      <FaArrowRightLong />
                    </h5>
                  </div>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default BlogsNews;
