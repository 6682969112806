import axios from "axios";
import { baseUrl } from "../../apis/constants";
import { useContext, useEffect, useState } from "react";
import { UserStates } from "../../context/UserContext";
import { MinusIcon, PlusIcon } from "@heroicons/react/16/solid";

export default function AddToCart({ id, padding, justify }) {
  const { accessToken, userId, cart, setCart } = useContext(UserStates);

  const [filteredItem, setFilteredItem] = useState("");

  const styles = {
    btnStyles: {
      background: "#013220",
      padding: padding !== undefined ? padding : "10px 20px",
      borderRadius: "5px",
      color: "white",
      fontWeight: "500",
      width: "100%",
      display: "flex",
      justifyContent: justify !== undefined ? justify : "space-between",
      alignItems: "center",
      fontSize: "13px",
      //   height: "35px",
    },
    svg: {
      width: "15px",
      height: "15px",
      cursor: "pointer",
    },
  };

  const addToCart = (type) => {
    const config = {
      method: "post",
      url: `${baseUrl}carts`,
      data: {
        productId: id,
        userId,
        quantity: type === "plus" ? 1 : -1,
      },
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => setCart(res.data.data))
      .catch((err) => err);
  };

  useEffect(() => {
    const item = cart?.find((item) => item.product._id === id);
    setFilteredItem(item);
  }, [id, cart]);

  return (
    <div style={styles.btnStyles}>
      {filteredItem?.quantity > 0 ? (
        <>
          <MinusIcon style={styles.svg} onClick={() => addToCart("minus")} />
          {filteredItem.quantity}
          <PlusIcon style={styles.svg} onClick={() => addToCart("plus")} />
        </>
      ) : (
        <p
          style={{ margin: "auto", cursor: "pointer" }}
          onClick={() => addToCart("plus")}
        >
          Add to cart
        </p>
      )}
    </div>
  );
}
