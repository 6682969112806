import React, { useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import AddressForm from "./AddressForm";
import { FaChevronUp } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import { FaPen } from "react-icons/fa";

const EditAddress = () => {
  const [edit, setEdit] = useState(false);
  const [text, setText] = useState(
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ea iusto consequatur soluta porro temporibus non perferendis dolore error? Reiciendis in, expedita veritatis culpa velit quod minima nisi asperiores veniam."
  );
  return (
    <div>
      <div
        onClick={(e) => setEdit((pre) => !pre)}
        className="text-lg  bg-white p-2 border border-gray-300 rounded-lg flex justify-between mb-2"
      >
        <p>{text.length > 10 ? text.slice(0, 30) + "..." : text}</p>
        <div className="flex gap-1 items-center pe-4">
          <RiDeleteBin6Line className="text-red-500" />
          {/* <FaPen className=" text-sm text-gray-500"/> */}
          {edit ? (
            <FaChevronUp className="text-gray-500 text-sm" />
          ) : (
            <FaChevronDown className="text-gray-500 text-sm" />
          )}
        </div>
      </div>
      {edit && (
        <div>
          <AddressForm />
          <div className="flex justify-center gap-3 px-3 mt-3">
            <div className="text-[#013220] bg-white py-2 text-center rounded-md w-72 mb-5">
              Cancel
            </div>
            <div className="bg-[#013220] text-white py-2 text-center rounded-md w-72 mb-5">
              Update
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditAddress;
