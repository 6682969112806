import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

export default function Title({ header, url }) {
  const navigate = useNavigate();

  return (
    <div className="flex justify-between" style={{ marginBottom: "10px" }}>
      <h1 className="text-l font-normal">{header}</h1>
      <h5
        className="text-[#00BF63] flex justify-center items-center gap-2 cursor-pointer"
        onClick={() => navigate(url)}
      >
        show all <FaArrowRightLong className="text-xs" />
      </h5>
    </div>
  );
}
