import React from 'react'
import { IoLocationOutline } from 'react-icons/io5'

const Map = ({setSelect}) => {
  return (
    <div>
        <img className='w-full' src="https://s3-alpha-sig.figma.com/img/17c2/6cca/d07ecebdc8fc8cef7bcabf22e10fb7cf?Expires=1714348800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Gkkvh~4yq3YPOr3pLQHcH0JcgWiJijiZRJqCZPiamxrg3eCRbELN9zbGgKCyErKyknWkbPkFMtl6k8Lb1Jv39Mt-Ft3DzlHPzZlRrXlknPJtxOg4bgHKvhCHdeysVdHfuNGaxfktxZZaYjUj608cAs4o8keWP5sQRoFUaJBz7HT~oLLsxAHqfdRtJ4XrMlWKLlZUFu8JylqR2ih6fKYKQN5DfmdcoX1v6Wf6AJGxhaKyORDQu1DDifrLRN00iQCEfDz99ez1J4ysL5Emkv32JN7hcMASy7lHFh9Z5heb1WQcXXNVqrzlijDmRE9ANm7pyXiwwAnA7Aw6gL4j0548mw__" alt="" />
        <div onClick={(e)=>setSelect("map")} className='flex bg-[#00BF63] text-white justify-center items-center p-1 text-lg rounded-md mt-2 mb-1'> 
           <IoLocationOutline/> <p>Use current location</p>
        </div>
    </div>
  )
}

export default Map