import Api from "../utils/Api";
import brands from "../utils/endpoints/Brands";

export const getAllBrands = async () => {
  try {
    const res = await Api.post(brands.getBrands());
    return res;
  } catch (err) {
    throw err;
  }
};

export const getBanner = async () => {
  try {
    const res = await Api.post(brands.getBanner());
    return res;
  } catch (error) {
    console.log(error);
  }
};
