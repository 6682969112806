import dayjs from "dayjs";
import React from "react";

const TimeLine = ({ items }) => {
  return (
    <div>
      {console.log("statuses", items)}
      {items?.map((item) => (
        <ol className="relative border-s border-[#00BF63]  text-[#00BF63]">
          <li className="ms-4">
            <div className="absolute w-3 h-3 bg-[#00BF63] rounded-full mt-0 -start-1.5 border border-[#00BF63] "></div>
            <time className="mb-1 text-base font-normal leading-none  ">
              {item.status}
            </time>
            <p className="text-sm font-normal text-gray-500 ">
              {dayjs(item.date).format("ddd-MM-YYYY hh:mm a")}
            </p>
          </li>
        </ol>
      ))}
    </div>
  );
};

export default TimeLine;
