/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";

import { UserStates } from "../../context/UserContext";
import { getAllMarketPlaceListings } from "../../apis/constants";
import styles from "./home.module.css";
import { useNavigate } from "react-router-dom";
import Title from "./titles";

const Latest = () => {
  const navigate = useNavigate();

  const accessToken = Cookies.get("gefmartUserToken");

  const { currentLocation } = useContext(UserStates);

  const [data, setData] = useState([]);

  useEffect(() => {
    const getMarketPlaceItems = () => {
      const config = {
        method: "post",
        url: `${getAllMarketPlaceListings}/1`,
        data: {
          latitude: currentLocation.lat,
          longitude: currentLocation.lng,
        },
        // one in the api denotes pagination
        headers: { Authorization: accessToken },
      };

      axios(config)
        .then((res) => {
          setData(res.data.data);
        })
        .catch((err) => err);
    };

    if (accessToken !== undefined) {
      getMarketPlaceItems();
    }
  }, [currentLocation]);

  return (
    <div className="flex flex-col gap-2 lg:gap-4">
      {/* <div className="flex justify-between">
        <h1 className="text-xl font-normal">Latest in Marketplace</h1>
        <h5
          className="text-[#00BF63] flex justify-center items-center gap-2 cursor-pointer"
          onClick={() => navigate("/marketplace")}
        >
          show all <FaArrowRightLong className=" text-xs" />
        </h5>
      </div> */}
      <Title header={"Latest in Marketplace"} url={"/marketplace"} />
      <div className="flex justify-between">
        <div style={{ display: "flex", overflowX: "scroll" }}>
          {data?.map((item) => (
            <div
              className={`bg-[#013220] ${styles.marketPlaceListing}`}
              onClick={() => navigate(`/marketplace/${item._id}`)}
            >
              <div
                style={{ display: "flex" }}
                className={styles.internalWrapper}
              >
                <img
                  src={item.images[0]}
                  alt="gefmart-marketplace"
                  style={{
                    width: "95px",
                    height: "95px",
                    borderRadius: "5px",
                    marginRight: "10px",
                  }}
                />
                <div style={{ marginRight: "10px", width: "100%" }}>
                  <h2 className="text-base lg:text-base text-white">
                    {item.name}
                  </h2>
                  <h2 className=" text-xs lg:text-sm text-white">{`${item.brand.name}, ${item.category.name}`}</h2>
                  <p className="text-slate-500 flex justify-between w-full text-xs lg:text-sm mt-2">
                    {new Date(item.createdAt).toDateString()}
                  </p>
                  {/* <p>Contact for Price</p> */}
                </div>

                {/* <div className="flex gap-4 justify-center items-center">
                  <h1 className="text-[#00BF63] text-2xl lg:text-3xl font-bold">
                    x{item.quantity}
                  </h1>
                  <a href={`tel:+${item?.user?.number}`}>
                    <div className=" text-3xl lg:text-5xl text-black bg-white p-1 rounded-md">
                      <MdOutlinePhone />
                    </div>
                  </a>
                </div> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Latest;
