import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

import { getAllBrandsUrl } from "../../apis/constants";
import Title from "./titles";

const Brands = () => {
  const accessToken = Cookies.get("gefmartUserToken");

  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    const getAllCategories = () => {
      const config = {
        method: "post",
        url: getAllBrandsUrl,
        headers: { Authorization: accessToken },
      };

      axios(config)
        .then((res) => {
          setData(res.data.data);
        })
        .catch((err) => err);
    };

    if (accessToken !== undefined) {
      getAllCategories();
    }
  }, [accessToken]);

  return (
    <div className="flex flex-col gap-4">
      <Title header={"Top Brands"} url={"/brands"} />
      <div className="flex justify-start overflow-scroll gap-4 lg:gap-14 lg:overflow-y-hidden w-full">
        {data?.map((item, index) => (
          <div
            className="flex flex-col mr-3 justify-center items-center rounded-lg border p-2 w-32 h-48 lg:w-96"
            key={index}
          >
            <div
              className=" h-28 w-28 cursor-pointer"
              onClick={() =>
                navigate({ pathname: "products" }, { state: item.name })
              }
            >
              <img
                className="object-cover max-w-24 lg:max-w-max lg:w-full h-full rounded-lg"
                src={item?.image}
                alt=""
              />
            </div>
            <h3 className="text-log mt-1">{item.name}</h3>
            {/* <h5 className="text-[#00BF63] sm:text-sm text-xs">10% off on cables</h5> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Brands;
