import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";

import { NavbarDefault } from "../../Components/Home/Navbar";
import Footer from "../../Components/Home/Footer";
import {
  getAllMarketPlaceListings,
  marketPlaceByCategoryUrl,
} from "../../apis/constants";

const Categories = () => {
  const navigate = useNavigate();

  const accessToken = Cookies.get("gefmartUserToken");

  const [curr, setCurr] = useState();
  const [data, setData] = useState([]);
  const [items, setItems] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");

  const getCategories = () => {
    const config = {
      method: "get",
      url: marketPlaceByCategoryUrl,
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => {
        setData(res.data.data);
        setCurr(res.data.data[0].category._id);
      })
      .catch((err) => console.log(err.message));
  };

  const getItemsByCategory = (page) => {
    const config = {
      method: "post",
      url: `${getAllMarketPlaceListings}/${page}`,
      data: { categoryId: curr, search },
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => {
        setTotalPages(res.data.totalPages);
        // setItems(res.data.data);
        if (page === 1) {
          setItems(res.data.data);
        } else {
          setItems([...items, ...res.data.data]);
        }
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (curr !== undefined) {
      getItemsByCategory(1);
    }
  }, [curr]);

  // search
  useEffect(() => {
    // Function to check if the user has reached the bottom of the page
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight - 10 &&
        !loading &&
        totalPages !== pageNo
      ) {
        setPageNo((prevPageNo) => {
          setLoading(true);
          const nextPage = prevPageNo + 1;
          getItemsByCategory(nextPage);
          return nextPage;
        });
      }
    };

    // Add event listener for scrolling
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, [data, loading]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    if (debouncedSearch.length > 3 || search.length === 0) {
      getItemsByCategory(1);
    }
  }, [debouncedSearch]);

  return (
    <div className="bg-[#F5F5F5] w-screen">
      <NavbarDefault />
      <div
        className="navigationSearch"
        onClick={() => navigate("/marketplace")}
      >
        Search for marketplace products
      </div>
      <div className="md:px-10 px-3 pt-2">
        <div className="flex w-full">
          <div className="w-1/3 py-3">
            <div
              className="flex flex-col w-full h-full overflow-scroll lg:overflow-x-scroll items-center"
              style={{ height: "100vh" }}
            >
              {data?.map((item, index) => (
                <div
                  onClick={() => setCurr(item.category._id)}
                  className={`flex max-w-28 flex-col justify-center items-center mb-5 text-center md:pr-10 w-full border-r-2 ${
                    curr === item.category._id && "border-r-2 border-[#013220]"
                  }`}
                  key={index}
                >
                  <img
                    className="rounded-full max-w-16 h-16 object-cover lg:max-w-28 lg:w-28 lg:h-28"
                    src={item.category.image}
                    alt="category img"
                  />
                  <h3 className="mt-2 text-xs lg:text-sm text w-24">
                    {item.category?.name}
                  </h3>
                </div>
              ))}
            </div>
          </div>
          <div className="w-2/3 grid md:grid-cols-3 grid-cols-2 align-top h-full gap-3 md:gap-5 mr-2 md:mr-0">
            {items.map((item) => (
              <div className="border shadow-md p-2 max-w-52 md:h-54">
                <Link to={`/marketplace/${item._id}`}>
                  <div className="border">
                    <img
                      src={item.images[0]}
                      alt="gefmart marketplace item"
                      className="w-full md:h-36 h-28 object-contain"
                    />
                  </div>
                  <p className="w-full text-center mt-1 md:text-lg">
                    {item.name}
                  </p>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Categories;
