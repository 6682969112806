import React from 'react';
import LatestItem from './LatestItem';

const ShowLatest = React.memo(({ latest }) => {
  return (
    <div>
      {
        latest.map((p) => {
          return <LatestItem key={p.id} product={p}/>;
        })
      }
    </div>
  );
});

export default ShowLatest;
