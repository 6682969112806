import React, { useState } from "react";
import CartToggle from "../../Components/Cart/CartToggle";
import { NavbarDefault } from "../../Components/Home/Navbar";
import Footer from "../../Components/Home/Footer";
import MyCart from "../../Components/Cart/MyCart";
import MyOrders from "../../Components/Cart/MyOrders";
import MobileMenuBar from "../../Components/Home/MobileMenuBar";
import ManageAddress from "../../Components/Address/ManageAddress";

const Cart = () => {
  const [toggle, setToggle] = useState(true);

  return (
    <div className="bg-[#F5F5F5] w-screen min-h-screen" >
      <NavbarDefault />
      <div className="p-3 md:px-10 md:mb-7">
        <CartToggle setToggle={setToggle} toggle={toggle} />
        {toggle ? <MyCart /> : <MyOrders />}
        <ManageAddress/>
      </div>
      <MobileMenuBar />
      <Footer />
    </div>
  );
};

export default Cart;
